<template>
  <!-- Add new vip modal -->
  <b-modal
    id="modal-new-vip"
    :visible="isEditVipModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.update')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('vip.form.editHeader')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('vip.form.name')"
        label-for="price"
        :invalid-feedback="$t('vip.form.nameInvalid')"
      >
        <cleave
          id="price"
          v-model="price"
          class="form-control"
          :class="{ 'is-invalid': !nameState, 'is-valid': nameState }"
          :raw="true"
          :options="options.price"
          placeholder="Price"
        />
      </b-form-group>
    </form>
    <template v-slot:modal-ok>
      <b-button variant="primary" :disabled="isLoading">
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          {{ $t('common.update') }}
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BFormGroup, BModal, BSpinner, VBModal } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BModal,
    BSpinner,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: ['isEditVipModalShow', 'data'],
    event: ['update:is-edit-vip-modal-show', 'update:data'],
  },
  props: {
    isEditVipModalShow: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const price = ref('')
    const vipState = ref(false)
    const nameState = ref(null)
    const form = ref(null)
    const isLoading = ref(false)
    const toast = useToast()
    const options = {
      price: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
        rawValueTrimPrefix: true,
      },
    }

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    watch(
      () => props.isEditVipModalShow,
      val => {
        if (val) {
          price.value = props.data.price
          nameState.value = true
        } else nameState.value = true
      },
    )

    watch(
      () => price.value,
      val => {
        if (val === '') {
          nameState.value = false
        }
      },
    )

    const handleSubmit = () => {
      if (!checkFormValidity() || price.value === '') {
        nameState.value = false
        // eslint-disable-next-line no-useless-return
        return
      }
      isLoading.value = true
      // EDIT VIP NAME //
      store
        .dispatch('master-vip/editVip', {
          // eslint-disable-next-line no-underscore-dangle
          id: props.data._id,
          price: price.value,
        })
        .then(() => {
          emit('refetch-data')
          emit('update:is-edit-vip-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success editing a vip',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error editing a vip',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (props.data !== price.value) {
        handleSubmit()
      } else {
        emit('update:is-edit-vip-modal-show', false)
      }
    }

    const resetModal = () => {
      price.value = ''
      nameState.value = null
      emit('update:is-edit-vip-modal-show', false)
      emit('update:data', null)
    }

    return {
      price,
      vipState,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      options,
      isLoading,
    }
  },
}
</script>
