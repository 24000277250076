<template>
  <!-- Add new vip modal -->
  <b-modal
    id="modal-new-vip"
    :visible="isAddNewVipModalShow"
    cancel-variant="outline-secondary"
    :ok-title="$t('common.create')"
    :cancel-title="$t('common.cancel')"
    centered
    :title="$t('vip.form.header')"
    :ok-disabled="isLoading"
    @ok="handleOk"
    @hidden="resetModal"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="nameState"
        :label="$t('vip.form.name')"
        label-for="price"
        :invalid-feedback="$t('vip.form.nameInvalid')"
      >
        <cleave
          id="price"
          v-model="price"
          class="form-control"
          :class="{ 'is-invalid': !nameState, 'is-valid': nameState }"
          :raw="true"
          :options="options.price"
          placeholder="Price"
        />
      </b-form-group>
    </form>
    <template #modal-ok>
      <b-button variant="primary" :disabled="isLoading">
        <template v-if="isLoading">
          <div class="d-flex align-center">
            <b-spinner small type="grow" class="mr-1" />
            Loading...
          </div>
        </template>
        <span v-else>
          {{ $t('common.create') }}
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BFormGroup, BModal, BSpinner, VBModal } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

import store from '@/store'

export default {
  components: {
    BFormGroup,
    BModal,
    BSpinner,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-vip-modal-show',
  },
  props: {
    isAddNewVipModalShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const price = ref(null)
    const nameState = ref(null)
    const form = ref(null)
    const isLoading = ref(false)
    const toast = useToast()
    const options = {
      price: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        prefix: 'Rp ',
        rawValueTrimPrefix: true,
      },
    }

    const checkFormValidity = () => {
      nameState.value = form.value.checkValidity()
      return nameState.value
    }

    watch(price, newValue => {
      if (newValue === '') {
        nameState.value = false
      } else nameState.value = true
    })

    const handleSubmit = () => {
      if (!checkFormValidity() || price.value === '') {
        // eslint-disable-next-line no-useless-return
        nameState.value = false
        return
      }
      isLoading.value = true
      store
        .dispatch('master-vip/addVip', price.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-vip-modal-show', false)
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success creating new vip',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          isLoading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new vip',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmit()
    }

    const resetModal = () => {
      price.value = ''
      nameState.value = null
      emit('update:is-add-new-vip-modal-show', false)
    }

    return {
      price,
      form,
      handleSubmit,
      nameState,
      handleOk,
      resetModal,
      options,
      isLoading,
    }
  },
}
</script>
